<template>
    <div>      
        <v-container xl fluid>
            <v-row>
                <v-overlay :value="overlay" >
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1"
                        >
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60"
                            ></v-img>
                        </v-progress-circular>
                        <p>{{fraseOverlay}}</p>
                    </div>
                    
                </v-overlay>
                <v-col cols="12" lg="12" class="center">
                    <div class="margen d-flex mb-10">
                        <hr class="vr"/>
                        <p class="tblName">Redactar otros Documentos</p>
                    </div>

                    <div id="loading" v-show="isLoading">
                        <v-progress-circular indeterminate :size="100" :width="7" color="blue" ></v-progress-circular> 
                    </div>
                    <v-row v-show="!isLoading" >
                        <v-col cols="12" xs="12" sm="4" md="4" class="pt-0">
                            <v-col cols="12" xs="12" sm="12" md="12" class="pa-0" >
                                <perfect-scrollbar class="left-menu pa-4" >
                                    <v-col cols="12" xs="12" sm="12" md="12" class="text-center mt-8">
                                        <img class="logo" :src="logoEmpresa"/>
                                    </v-col>
                                    <template>
                                        <v-card elevation="0">
                                            <v-tabs v-model="tab" background-color="transparent" grow style="height:30px;" class="">
                                                <v-tab v-for="item in items" :key="item" class="pa-0 " style="height:30px; align-content:flex-start !important;">
                                                    <span class="tabText">{{ item }}</span>
                                                </v-tab>
                                            </v-tabs>

                                            <v-tabs-items v-model="tab">
                                                <v-tab-item :key="0">
                                                    <v-card flat>
                                                        <v-card-text class="pa-0">
                                                             <v-col cols="12" xs="12" sm="12" md="12"> 
                                                                <ValidationObserver ref="form" v-slot="{ invalid }">
                                                                    <p class="sub-title" >{{isVariant?"Variante actual":"Documento actual"}}</p>
                                                                    <v-row>
                                                                        <v-col cols="12" xs="12" sm="12" md="12" v-if="rol == 'root'" class="pt-0 pb-0 ma-0">
                                                                            <v-autocomplete
                                                                                outlined
                                                                                label="Cliente"
                                                                                :items="clientes"
                                                                                item-text="nombre"
                                                                                item-value="id"
                                                                                persistent-hint
                                                                                v-model="contrato.cliente_id"
                                                                                :disabled="tiene_archivo"
                                                                                class="pa-0 ma-0"
                                                                                id="txtCliente"
                                                                            >
                                                                            </v-autocomplete>
                                                                        </v-col>
                                                                        <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                                                            <v-autocomplete
                                                                                outlined
                                                                                label="Empresa"
                                                                                :items="empresas"
                                                                                item-text="nombre"
                                                                                item-value="id"
                                                                                persistent-hint
                                                                                v-model="contrato.empresa_id"
                                                                                class="pa-0 ma-0"
                                                                                :disabled="tiene_archivo"
                                                                            >
                                                                            </v-autocomplete>
                                                                        </v-col>
                                                                        <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                                                            <v-autocomplete
                                                                                outlined
                                                                                label="Sucursal"
                                                                                :items="sucursales"
                                                                                item-text="nombre"
                                                                                item-value="id"
                                                                                persistent-hint
                                                                                v-model="contrato.sucursal_id"
                                                                                class="pa-0 ma-0"
                                                                                :disabled="tiene_archivo"
                                                                            >
                                                                            </v-autocomplete>
                                                                        </v-col>
                                                                        <v-col xs="12" sm="12" md="12" class="py-0" v-if="documento_nuevo">
                                                                            <ValidationProvider v-slot="{ errors }" name="Nombre del documento" rules="required">
                                                                                <v-text-field
                                                                                    outlined
                                                                                    id="nombre"
                                                                                    label="Nombre del documento"
                                                                                    v-model="contrato.nombre"
                                                                                    :error-messages="errors" 
                                                                                    required
                                                                                    :disabled="tiene_archivo"
                                                                                />
                                                                            </ValidationProvider>
                                                                        </v-col>
                                                                        <v-col v-else  xs="12" sm="12" md="12" class="py-0" >
                                                                            <v-autocomplete
                                                                                outlined
                                                                                label="Documento"
                                                                                :items="itemsContratos"
                                                                                item-text="nombre"
                                                                                return-object
                                                                                v-model="documento_seleccionado"
                                                                            >   
                                                                            </v-autocomplete>

                                                                        </v-col>
                                                                        <v-col xs="12" sm="12" md="12" class="py-0">
                                                                            <ValidationProvider v-slot="{ errors }" name="Tipo de hoja" rules="required">
                                                                                <v-autocomplete
                                                                                    outlined
                                                                                    label="Tipo de hoja"
                                                                                    :items="itemsTipoHojas"
                                                                                    item-text="descripcion"
                                                                                    item-value="clave"
                                                                                    persistent-hint
                                                                                    v-model="tipoHoja"
                                                                                    class="pa-0 ma-0"
                                                                                    :error-messages="errors" 
                                                                                    required
                                                                                    :disabled="tiene_archivo"
                                                                                >
                                                                                </v-autocomplete>
                                                                            </ValidationProvider>
                                                                        </v-col>
                                                                        <v-col xs="12" sm="12" md="12" class="py-0">
                                                                           <v-file-input
                                                                            outlined
                                                                            label="Seleccionar archivo"
                                                                            v-model="contrato.archivo"
                                                                            accept=".doc, .docx"
                                                                            prepend-icon=""
                                                                            append-icon="attach_file"
                                                                            id="fileDocument"
                                                                            @click:append="open"
                                                                           ></v-file-input>
                                                                        </v-col>
                                                                        <v-col cols="12" v-if="tiene_archivo">
                                                                            <span class="sub-title">Descargar documento para edición:</span>
                                                                            <v-btn
                                                                                @click="descargarDocumento()"
                                                                                class="elevation-0 ml-2"
                                                                                icon
                                                                                fab
                                                                                dark
                                                                            >
                                                                                <img src="/static/icon/doc.png" style="width: 34px;">
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col xs="12" sm="12" md="12" class="py-0 mb-4">
                                                                          <v-checkbox
                                                                            v-model="tiene_relacion"
                                                                            label="Activar la relación con contrato"
                                                                            class="mt-0"
                                                                            :disabled="(contrato.cliente_id == null)"
                                                                          ></v-checkbox>  
                                                                        </v-col>
                                                                        <template v-if="tiene_relacion">
                                                                            <!--TODO: campos restantes-->
                                                                            <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                                                                <MultipleAutocomplete
                                                                                    outlined
                                                                                    :items="registroPatronal"
                                                                                    item_text="nombreClave"
                                                                                    @dataMultiple="(data)=> rp_seleccionados = data"
                                                                                    :valorDefecto="rp_seleccionados"
                                                                                    label="Registros Patronales"
                                                                                    ref="multRegistroPatronal"
                                                                                />
                                                                        </v-col>
                                                                            <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                                                            <MultipleAutocomplete
                                                                                outlined
                                                                                :items="rpContratos"
                                                                                item_text="nombre"
                                                                                @dataMultiple="(data)=> contratos_seleccionados = data"
                                                                                :valorDefecto="contratos_seleccionados"
                                                                                label="Contratos"
                                                                                ref="multContratos"
                                                                            />
                                                                        </v-col>
                                                                        </template>
                                                                    </v-row>
                                                                    <v-row class="pa-0 ma-0 d-flex justify-space-between">
                                                                        <div>
                                                                            <v-btn class="btnNormal mb-3 elevation-0 mr-2" @click="guardarContrato()" :disabled="invalid || contrato.cliente_id == null" :loading="isSaving">Guardar</v-btn>
                                                                            <v-btn class="btnNormal mb-3 elevation-0 mr-2" @click="eliminarContrato()" :disabled="invalid || contrato.cliente_id == null" v-if="isEditing" :loading="isSaving">Eliminar</v-btn>
                                                                            <v-btn class="btnCerrar mb-3 elevation-0" @click="reabrirContrato(contrato.referencia_contrato_id)" v-if="isVariant">Cancelar</v-btn>
                                                                        </div>
                                                                        <v-btn class="btnNormal mb-3 elevation-0 mr-2" @click="nuevoContrato()" v-if="!documento_nuevo">Nuevo</v-btn>
                                                                        <v-btn class="btnNormal mb-3 elevation-0 mr-2" @click="cancelarCreacion()" v-if="documento_nuevo" >Cancelar</v-btn>
                                                                    </v-row>
                                                                </ValidationObserver>
                                                                <v-divider class="hr"></v-divider>
                                                            </v-col>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-tab-item>
                                                <v-tab-item :key="1">
                                                    <v-card flat>
                                                        <v-card-text class="pa-0">
                                                            <v-col cols="12" xs="12" sm="12" md="12"> 
                                                                <p class="sub-title">Selecciona palabras clave para editar el contrato</p>
                                                                <div class="">
                                                                    <v-text-field
                                                                        class="buscador col-lg-12"
                                                                        outlined
                                                                        solo
                                                                        label="Buscar palabra"
                                                                        label-id="lblBuscador"
                                                                        v-model="searchName"
                                                                        autocomplete = "off"
                                                                    >
                                                                        <template slot="prepend-inner">
                                                                            <v-icon class="tamIcono color-icono">search</v-icon>
                                                                        </template>
                                                                    </v-text-field>
                                                                </div>
                                                                <div class="palabrasClave">
                                                                    <template v-for="(item,key) in filteredList">
                                                                        <v-btn 
                                                                            color="#F2F2F2" 
                                                                            class="mr-2 mb-2 elevation-0 btnVariable" 
                                                                            :key="key" 
                                                                            @click="insertarVariable(item.variable)"
                                                                        >
                                                                            {{item.nombre}}
                                                                        </v-btn>
                                                                    </template>
                                                                    
                                                                </div>
                                                                <!-- <v-divider class="hr"></v-divider> -->
                                                            </v-col>

                                                            <v-col cols="12" xs="12" sm="12" md="12">
                                                                <p class="sub-title">Selecciona palabras clave adicional</p>
                                                                <v-autocomplete
                                                                    outlined
                                                                    label="Cliente"
                                                                    :items="itemsClientes"
                                                                    item-text="nombre"
                                                                    item-value="id"
                                                                    persistent-hint
                                                                    v-model="cliente_value"
                                                                >
                                                                </v-autocomplete>
                                                                <v-autocomplete
                                                                    outlined
                                                                    label="Campo"
                                                                    :items="itemsCampos"
                                                                    item-text="campo_nombre"
                                                                    item-value="variable"
                                                                    persistent-hint
                                                                    v-model="contrato_campo_variable"
                                                                >
                                                                </v-autocomplete>

                                                                <div class="d-flex justify-end">
                                                                    <v-btn class="btnGuardar ma-0" @click="insertarVariable(contrato_campo_variable)">Agregar</v-btn>
                                                                </div>

                                                                
                                                            </v-col>

                                                            <v-col cols="12" xs="12" sm="12" md="12"> 
                                                                <p class="sub-title">Biblioteca de imágenes</p>
                                                                <v-divider class="hr"></v-divider>
                                                                <div class="d-flex justify-end mt-5">
                                                                    <v-btn class="btnNormal mb-3 elevation-0"  @click="dialogImagenes = true" :loading="isSaving">Subir imagen</v-btn>
                                                                </div>
                                                                <div>
                                                                    <template v-for="(item,key) in contratosImagenes.data">
                                                                        <img  :key="key"/>
                                                                        <v-badge
                                                                            :key="key"
                                                                            avatar
                                                                            :bordered="false"
                                                                            color="#fff"
                                                                            offset-x="35"
                                                                            offset-y="25"
                                                                        >
                                                                            <template v-slot:badge>
                                                                                <v-btn
                                                                                    :key="key"
                                                                                    @click="eliminarImagen(item.id)"
                                                                                    small
                                                                                    slot="activator"
                                                                                    icon
                                                                                    text
                                                                                    class="v-btn-cerrar"
                                                                                >
                                                                                    <v-icon class="icono-cerrar"></v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <v-avatar tile size="120" class="p-2">
                                                                                <v-img 
                                                                                    class="imgLibrary" 
                                                                                    :src="imgDrawer(item.url)" 
                                                                                    @click="contrato.contenido += '<img src='+imgDrawer(item.url)+'>'"
                                                                                ></v-img>
                                                                            </v-avatar>
                                                                        </v-badge>
                                                                    </template>
                                                                </div>
                                                            </v-col>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-tab-item>
                                            </v-tabs-items>
                                        </v-card>
                                    </template>
                                </perfect-scrollbar>
                            </v-col>
                        </v-col>
                        <v-col cols="12" xs="12" sm="8" md="8">
                            <div class="row">
                                <div class="col-lg-12" id="app" style="height:67.2vh; padding-top:0px;">
                                    <div class="document-editor__toolbar"></div>
                                    <div class="document-editor__editable-container" @click="zoom(0)">
                                        <div class="float-right" style="position:absolute; right:80px;">
                                            <div class="icon-container mb-2"
                                                style="margin-left: 12px;"
                                                @click="openOptions? openOptions = false : openOptions = true"
                                                title="Acciones"
                                            >
                                                <v-icon>more_horiz</v-icon>
                                            </div>
                                            <transition name="slide" v-if="openOptions">
                                                <div>
                                                    <div 
                                                        class="icon-container mb-2"
                                                        style="margin-left: 12px;"
                                                        @click="zoom(10)"
                                                        title="Acercar"
                                                    >
                                                        <v-icon>add</v-icon>
                                                    </div>
                                                    <div 
                                                        class="icon-container mb-2"
                                                        style="margin-left: 12px;"
                                                        @click="zoom(-10)"
                                                        title="Alejar"
                                                    >
                                                        <v-icon>remove</v-icon>
                                                    </div>
                                                    <div 
                                                        class="icon-container mb-2"
                                                        style="margin-left: 12px;"
                                                        @click="pageBreak()"
                                                        title="Salto de página"
                                                    >
                                                        <v-icon>vertical_align_center</v-icon>
                                                    </div>
                                                    <div 
                                                        class="icon-container mb-2"
                                                        style="margin-left: 12px;"
                                                        @click="clearContent()"
                                                        title="Borrar"
                                                    >
                                                        <v-icon>delete</v-icon>
                                                    </div>
                                                </div>
                                            </transition>
                                        </div>

                                        <!-- ****************************** -->
                                        <!-- ************CKEDITOR ********* -->
                                        <!-- ****************************** -->
                                        <ckeditor 
                                            id="ckEditor" 
                                            :editor="editor" 
                                            v-model="contrato.contenido" 
                                            :config="editorConfig" 
                                            @ready="onReady" 
                                            @blur="" 
                                            @focus="" 
                                            :disabled="editorDisabled"
                                            
                                        >
                                        </ckeditor>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                        <!-- <v-col cols="12" xs="12" sm="12" md="12" >
                            <perfect-scrollbar>
                                <v-col cols="12" xs="12" sm="12" md="12"  class="d-flex document-slider pa-0">
                                    <div class="slider-container">
                                        <div class="sliderPrev">
                                            <v-btn 
                                                class="btnContrato" 
                                                elevation="0" 
                                                @click="nuevoContrato()" 
                                            >
                                                <v-icon size="35px" color="#999999">post_add</v-icon>
                                            </v-btn>
                                        </div>
                                        <p>Documento nuevo</p>
                                    </div>
                                    <template v-if="Loading">
                                        <div class="col-lg-10 py-9">
                                            <v-progress-linear
                                                :active="Loading"
                                                class="loading mt-10"
                                                color="blue"
                                                indeterminate
                                            >
                                            </v-progress-linear>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <template v-for="(item) in itemsContratos">
                                            <div class="slider-container" :key="item.id">
                                                <div class="sliderPrev">
                                                    <v-btn 
                                                        class="btnContrato" 
                                                        elevation="0" 
                                                        @click="abrirContratoSlider(item)"
                                                    >
                                                        <v-icon size="35px" class="material-icons-outlined" color="#999999">description</v-icon>
                                                    </v-btn>
                                                </div>
                                                <p>{{item.claveDescripcion}}</p>
                                            </div>
                                        </template>
                                    </template>
                                </v-col>
                            </perfect-scrollbar>
                        </v-col>-->
                    </v-row> 
                </v-col>
            </v-row>
        </v-container>

      
     
        <v-dialog v-model="dialogImagenes" persistent max-width="500px" >
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
            </template>
            <v-card class="borde-card px-0">
                <ValidationObserver ref="form" v-slot="{ invalid }">
                    <v-card-title class="justify-center">
                        <div class="headerModal">
                            <img :src="'/static/modal/crear_carpeta.svg'" class="imgModal" />
                            <h2 class="titleModal">Subir Imagen</h2>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-container grid-list-md id="contenedor">
                            <v-divider class="divider"></v-divider>
                            <v-row>
                                <v-col xs="12" sm="12" md="12" class="py-0">
                                    <ValidationProvider v-slot="{ errors }" name="Imagen" rules="required">
                                        <v-text-field 
                                            outlined
                                            readonly
                                            label="logotipo.png" 
                                            @click='pickFile' 
                                            v-model='nombre_archivo' 
                                            :errors='errors'
                                            append-icon='attach_file'
                                        >
                                        </v-text-field>
                                        <input
                                            type="file"
                                            style="display: none"
                                            ref="image"
                                            accept="image/*"
                                            @change="onFilePicked"                      
                                        >
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-layout row>
                            <v-flex class="justify-center">
                                <div class="btnModal">
                                    <v-btn class="btnGuardar modal-pull-right" @click="agregarImagen()" :disabled="invalid" :loading="isSaving">Guardar</v-btn>
                                    <button class="btnCerrar modal-pull-right" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-card-actions>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
//imports

//componentes
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import FloatingView from "@/components/FloatingView2.vue";
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/es';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import VuetifyMultipleAutocomplete2 from '@/components/VuetifyMultipleAutocomplete2.vue'

//plugins
import Vue from 'vue';
import urlImg from '../plugins/Urlimagen';
import Notify from '@/plugins/notify';
import gFunctions from '@/js/global_functions';

//apis
import clientesApi from '@/api/clientes';
import empresasApi from '@/api/empresas'
import sucursalesApi from '@/api/sucursales'
import apiContratos from '@/api/contratos';
import queries from '@/queries/Kardex';
import queriesC from '@/queries/Contratos';
import apiRegistroPatronal from "@/api/nominas/registroPatronal";
        
export default {
    name:"",
    components: {
        VuetifyDatePicker,
        FloatingView,
        'MultipleAutocomplete': VuetifyMultipleAutocomplete2
    },
    
    data() {
        return {
            overlay : false,
            fraseOverlay : "Generando",
            rol:null,
            tab:0,
            items: ['Documento', 'Recursos'],
            itemsContratos : [],
            itemsVariantes : [],
            contrato: {
                id: 0,
                cliente_id: null,
                empresa_id: null,
                sucursal_id: null,
                referencia_contrato_id: null,
                tipo: "EMPLEADOS",
                nombre: null,
                fecha_inicio: "",
                fecha_fin: "",
                variante: false,
                carpeta: "",
                contenido: '',
                empleados: [],
                tamanio_papel: null,
                es_otro_documento: true,
                archivo: null
            },
            nombre_variante : null,
            nombre_carpeta  : null,
            carpeta_value   : null,
            searchName      : '',
            checkAll        : false,
            logoEmpresa     : '/static/bg/cliker_login.png',
            dialogEmpleados : false,
            dialogVariantes : false,
            dialogImagenes : false,
            nombre_archivo:null,
            archivo : null,
            imgDialogVariante : null,
            titleDialogVariante : null,
            isVariant       : false,
            isArchive       : false,
            isLoading       : true,
            Loading         : false,
            isEditing       : false,
            isSaving        : false,
            cambios         : false,
            openOptions     : true,
            tiposContrato   : [],
            clientes        : [],
            empresas        : [],
            sucursales      : [],
            itemsLinks      : [
                {
                    text: 'Contratos',
                    disabled: false,
                    to: '/contratos-empleados',
                },
                {
                    text: 'Redactar Contratos',
                    disabled: true,
                    to: '/redactar-contratos-empleado',
                },
            ],
            carpetas:[{id:1,nombre:"General",variantes:[]}],
            selectedArichive : -1,
            selectedContract : -1,
            datosLogin : null,
            empresa : null,
            employees : [],
            empleadosAsignados : [],
            itemsEmpleados :[],
            contratosVariables : {data:[]},
            contratosImagenes : [],
            editor: DecoupledEditor,
            editorDisabled: false,
            editorConfig: {
                language: "es",
                allowedContent : true,
            },
            sheetHeight : 1342, 
            maxSheetHeight:1342,
            currentZoom : 90,
            filters:{AND:[{column: "CLIENTE_ID", operator:"IS_NULL"},{column:"EMPRESA_ID",operator:"IS_NULL"},{column:"SUCURSAL_ID",operator:"IS_NULL"},{column:"TIPO",value:"EMPLEADOS"}]},
            itemsClientes       : [],
            cliente_value       : null,
            itemsCampos         : [],
            contrato_campo_variable   : null,

            itemsTipoHojas: [
                {
                    descripcion: 'Legal',
                    clave: 'legal'
                },
                {
                    descripcion: 'A4',
                    clave: 'A4'
                },
                {
                    descripcion: 'Letter',
                    clave: 'letter'
                },
                {
                    descripcion: 'Oficio',
                    clave: 'folio'
                },
            ],
            tipoHoja: 'legal',
            tiene_relacion: false,
            contratos_seleccionados: [],
            registroPatronal: [],
            rpContratos: [],
            rp_seleccionados: [],
            tiene_archivo: false,
            documento_nuevo: false,
            documento_seleccionado: null
            
        }
    },
    watch: {
        contrato: {
            deep:true,
            handler(newW,old){
                
                //console.log("edit",this.isEditing);
                if(this.isEditing){
                    this.cambios = true;
                    
                    //console.log(1);
                }else if((this.contrato.id == 0) && this.contrato.contenido !== ""){
                   // console.log(2);
                    this.cambios = true;
                }

                //console.log("camb",this.cambios);
            }
        
        },
        'contrato.cliente_id':function(newVal){
            /* if(this.contrato.cliente_id != newVal)
                this.contrato.registro_patronal_id;
            this.getRegistroPatronal(); */
            if(newVal!=null){
                this.getEmpresas(newVal)

                this.$apollo.queries.contratosImagenes.refetch();
                this.contrato.id  =  0;
                this.contrato.registro_patronal_id = null;
                this.contrato.nombre =  "";
                this.contrato.contenido =  "";
                this.contrato.sat_tipo_contrato_id = null;
                this.isEditing = false;
                this.cambios = false;

                if(this.tiene_relacion){
                    this.getRegistroPatronal()
                }
            }
        },
        tiene_relacion: function(val){
            if(val == true){
                if(this.contrato.cliente_id != null){
                    this.getRegistroPatronal();
                }
            }
        },
        'contrato.empresa_id': function(val) {
            if(val != null){
                this.getSucursales(val)
            }
        },
       'contrato.sucursal_id':function(val) {
          if(val != null){
            this.filters = {AND:[{column: "CLIENTE_ID", value:this.contrato.cliente_id},{column: "EMPRESA_ID", value:this.contrato.empresa_id},{column: "SUCURSAL_ID", value:this.contrato.sucursal_id},{column:"TIPO",value:"EMPLEADOS"}]}
          }  
        },

        rp_seleccionados:{
            deep:true,  
            handler(newVal, oldVal){
                if(newVal != oldVal){
                    this.contratos_seleccionados = []
                }
                this.getContratos();
            }
        },

        documento_seleccionado:function(val){
            if(val != null){
               console.log(val)
              this.abrirContratoSlider(val)
            }
        },
        /* 'contrato.registro_patronal_id':function(val){
            
            if(val == null){
                this.tipoHoja= 'legal'
            }

            if(val != null){
                this.Loading = true;
                this.filters = {AND:[{column: "CLIENTE_ID", value:this.contrato.cliente_id},{column: "REGISTRO_PATRONAL_ID", value:this.contrato.registro_patronal_id},{column:"TIPO",value:"EMPLEADOS"}]}
                this.$apollo.queries.contratosFormatos.skip = false;
                this.contrato.id                   =  0;
                this.contrato.nombre =  "";
                this.contrato.contenido =  "";
                this.contrato.sat_tipo_contrato_id = null;
                this.isEditing = false;
                this.cambios = false;
            }
        }, */
        checkAll(){
            this.selectAllEmployees();
        },
        cliente_value: function(val, oldVal){
            if(val != null){
                this.getCampos();
            }
        }
    },
    computed: {
        filteredList() {
            return this.contratosVariables.data.filter(item => {
                return item.nombre.toLowerCase().includes(this.searchName.toLowerCase())
            })
        }
    },
    methods: {

//funciones para el editor
        onReady(editor) {
           //incicia el CK Editor
            const toolbarContainer = document.querySelector(  '.document-editor__toolbar' );
            toolbarContainer.appendChild( editor.ui.view.toolbar.element );
        },
        zoom(zoom){
            //Funcion zoom en la hoja del documento
            const hoja = document.querySelector(  '.ck-editor__editable' );
            //console.log(this.currentZoom);
            //console.log(zoom);

            if(this.currentZoom == 10){
                this.currentZoom = 20;
            }
            hoja.style.zoom = this.currentZoom + zoom + "%";
            this.currentZoom = this.currentZoom + zoom;
        },
        pageBreak(){
            
            this.contrato.contenido +=   '%salto_pagina%';
        },
        clearContent(){
            Notify.Alert("¿Seguro quieres limpiar todo el contenido?","El contenido no se podrá recuperar","Continuar",()=>{this.contrato.contenido = "";})
            
        },

//funciones para contratos
        abrirContrato(data){
            //visualiza un contrato previemante guardado del slider
            //console.log(data);
            if(!this.cambios){
                //console.log("contrato",data);
                this.isEditing = false;
                this.isVariant = data.variante;

                this.contrato.id = data.id;
                this.contrato.cliente_id = data.cliente_id;
                this.contrato.empresa_id = data.empresa_id
                this.contrato.sucursal_id = data.sucursal_id
                //this.contrato.registro_patronal_id = data.registro_patronal_id;
                //this.contrato.sat_tipo_contrato_id = data.sat_tipo_contrato_id;
                this.contrato.referencia_contrato_id = data.referencia_contrato_id;
                this.contrato.nombre = data.nombre;
                this.contrato.contenido = data.contenido;
                this.contrato.variante = data.variante;
                this.tipoHoja = data.tamanio_papel;
                /*this.empleadosAsignados = JSON.parse(JSON.stringify(data.empleados));
                for(let empleado of this.empleadosAsignados){
                    this.employees.push(empleado.empleado.id);
                }*/
                if(data.url_archivo){
                    this.tiene_archivo = true
                    this.editorDisabled = true
                    
                }else{
                    this.tiene_archivo = false
                    this.editorDisabled = false
                }
                //console.log(data)
                if(data.contratosVinculados.length > 0){
                    this.tiene_relacion = true
                    data.contratosVinculados.forEach((item) => {
                        if(!this.rp_seleccionados.includes(item.registro_patronal_id)){
                            this.rp_seleccionados.push(item.registro_patronal_id)
                        }
                    })

                    this.contratos_seleccionados = data.contratosVinculados.map((el) => el.id)
                    
                }
                else if(data.contratosVinculados.length == 0){
                    this.tiene_relacion = false
                    this.rp_seleccionados = []
                    this.contratos_seleccionados = []
                    this.rpContratos = []
                    this.registroPatronal = []
                }

               
                
                if(!this.isVariant)
                this.carpetas = JSON.parse(data.carpeta);
                
                setTimeout(()=>{this.isEditing = true;},100);
            }else{
                this.verificarCambios(()=>{this.abrirContrato(data);});
            }
            
        },
        reabrirContrato(id){
            for(let contrato of this.itemsContratos){
                if(contrato.id == id){
                    this.abrirContrato(contrato);
                }
            }
        },
        abrirContratoSlider(data) {
            this.contrato.archivo = null
            if (data.url_archivo) {
                this.abrirContrato(data)
                this.contrato.url_archivo = data.url_archivo
                // this.descargarDocumento(data)
            } else {
                this.abrirContrato(data)
            }
        },
        guardarContrato(){
            //funcion para guardar o actulizar un contrato o variante
            this.isSaving = true;
            
            //this.contrato.cliente_id = this.datosLogin.cliente_id;
            //this.contrato.empresa_id = this.empresa.id;

            this.contrato.carpeta = "[]";
            
            this.contrato.empleados = this.employees;

            //console.log(this.contrato,this.isEditing);
            /* if(this.contrato.registro_patronal_id){
                this.contrato.tamanio_papel = this.tipoHoja;
            } */

            this.contrato.tamanio_papel = this.tipoHoja

            if(this.tiene_relacion){
                //this.contrato.registro_patronal_id = this.rp_value
                this.contrato.contratosVinculados = this.contratos_seleccionados
            }

            /*
                contrato: {
                id: 0,
                cliente_id: null,
                empresa_id: null,
                sucursal_id: null,
                referencia_contrato_id: null,
                tipo: "EMPLEADOS",
                nombre: null,
                fecha_inicio: "",
                fecha_fin: "",
                variante: false,
                carpeta: "",
                contenido: '',
                empleados: [],
                tamanio_papel: null,
                es_otro_documento: true
            },
            */
            let formData = new FormData()

            formData.append("id", this.contrato.id)
            formData.append("cliente_id", this.contrato.cliente_id)
            formData.append("empresa_id", this.contrato.empresa_id)
            formData.append("sucursal_id", this.contrato.sucursal_id)
            formData.append("tipo", this.contrato.tipo)
            formData.append("nombre", this.contrato.nombre)
            formData.append("variante", this.contrato.variante)
            formData.append("carpeta", this.contrato.carpeta)
            formData.append("empleados", this.contrato.empleados)
            formData.append("tamanio_papel", this.contrato.tamanio_papel)
            formData.append("es_otro_documento", this.contrato.es_otro_documento)
            formData.append("tiene_archivo", this.tiene_archivo)
            if(this.contrato.contenido){
                formData.append("contenido",this.contrato.contenido)
            }
            if(this.contrato.contratosVinculados){
                formData.append("contratosVinculados",JSON.stringify(this.contrato.contratosVinculados))
            }
            if(this.contrato.archivo){
                formData.append("archivo", this.contrato.archivo)
            }
            

            if(!this.isEditing){ 
                apiContratos.addContrato(formData)
                .then(async (response) => {
                    //console.log("repuesta",response);
                    this.Loading = true;

                    if(this.isVariant){
                        await this.añadirVarienteACarpeta(response.id);
                    }

                    await this.$apollo.queries.contratosFormatos.refetch();

                    this.isSaving = false;
                    this.documento_nuevo = false
                    Notify.Success("Operación exitosa", "El contrato se guardó satisfactoriamente.",()=>{
                        this.cambios = false;
                        this.resetValues()
                        /* if(this.isVariant){
                            this.reabrirContrato(this.contrato.referencia_contrato_id);
                        }else{
                            this.reabrirContrato(response.id);
                        } */
                        
                    });
                })
                .catch(err => {
                    this.isSaving = false;
                    gFunctions.errorParse(err.response.data.error);
                });
            }else{
                if(this.isVariant){
                    this.añadirVarienteACarpeta(this.contrato.id);
                }

                this.isEditing = false;
                this.contrato.tiene_relacion = this.tiene_relacion
                apiContratos.updateContrato(formData)
                .then((response) => {
                    //console.log("repuesta",response);
                    this.isSaving = false;
                    this.Loading = true;
                    this.$apollo.queries.contratosFormatos.refetch();

                    Notify.Success("Operación exitosa", "El documento se actualizó satisfactoriamente.",()=>{
                        this.cambios = false;
                        this.resetValues()
                       /*  if(this.isVariant){
                            this.reabrirContrato(this.contrato.referencia_contrato_id);
                        }else{
                            this.reabrirContrato(response.id);
                        } */
                        
                    });
                    
                })
                .catch(err => {
                    this.isSaving = false;
                    gFunctions.errorParse(err.response.data.error);
                });
            }
        },
        eliminarContrato(){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar este documento?",
                "Eliminar",
                () => {
                    apiContratos.deleteFormat(this.contrato.id)
                    .then(async(result) => {
                        this.Loading = true;
                        await this.resetValues();
                        this.$apollo.queries.contratosFormatos.skip = false;
                        Notify.Success("Listo","El documento se elimino correctamente");
                    })
                    .catch((err) => {
                        Notify.ErrorToast(err);
                    });   
                }
            );
        },
        nuevoContrato(){
            this.tiene_archivo = false
            this.documento_nuevo = true
            this.resetValues();
        },
        cancelarCreacion(){
            this.documento_nuevo = false
            this.documento_seleccionado = null
            this.resetValues()
        },

        open(){
            document.getElementById("fileDocument").click()
        },
        descargarDocumento(){

            let nombre = this.contrato.nombre
            let archivo = this.contrato.url_archivo

            const FileDownload = require("js-file-download")
            const axios = require("axios")
            let url = "download"
            let parametros = {url: archivo}

            var nombre_archivo = ""
            let nombreArchivo = ""
            let archivo_extension = ""

            if(archivo){
                nombreArchivo = archivo.split("/")
                archivo_extension = nombreArchivo[nombreArchivo.length - 1].split(".");
                nombre_archivo =
                nombre + "." + archivo_extension[archivo_extension.length - 1];
            }

            try {
                axios({
                    method: "post",
                    url: url,
                    responseType: "blob",
                    data: parametros,
                }).then((response) => {
                    FileDownload(response.data, nombre_archivo);
                });
            } catch (e) {
                Notify.ErrorToast("Se produjo un error al realizar la petición.");
                console.log(e);
            }
        },

//funciones de variantes
        agregarVariante(){

            if(this.contrato.nombre.trim() == this.nombre_variante.trim()){
                Notify.ErrorToast("La variante no se puede llamar igual que el contrato.")
                return;
            }

            this.isVariant = true;
            this.isEditing = false;
            this.contrato.referencia_contrato_id = this.contrato.id;
            this.contrato.id = 0;
            this.contrato.nombre = this.nombre_variante;
            this.contrato.variante = true;

            this.cerrarModal();
        },
        async añadirVarienteACarpeta(id){
            if(this.isEditing){
                for(let carpeta of this.carpetas){
                    if(carpeta.id  == this.selectedArichive + 1){
                        for(let variante of carpeta.variantes){
                            if(variante.id == id){
                                variante.nombre = this.contrato.nombre;
                            }
                        }
                    }
                }
            }else{
                for(let carpeta of this.carpetas){
                    if(carpeta.id  == this.carpeta_value){
                        carpeta.variantes.push({id:id,nombre:this.contrato.nombre});
                    }
                }
            }
            
            //console.log("carpetas",this.carpetas);
            let params = {contrato_formato_id:this.contrato.referencia_contrato_id,carpeta:JSON.stringify(this.carpetas)};
            await apiContratos.updateCarpetaContrato(params)
                .then(async (response) => {
                    //console.log("repuesta carpetas",response);
                })
                .catch(err => {
                    this.isSaving = false;
                    gFunctions.errorParse(err.response.data.error);
                });

        },
        agregarCarpeta(){
            //crea una carpeta dinamica
            for(let carpeta of this.carpetas){
                if(carpeta.nombre == this.nombre_carpeta.trim()){
                    Notify.ErrorToast("La carpeta '"+this.nombre_carpeta+"' ya existe, intenta con una distinta.");
                    return;
                }
            }
            let  id = this.carpetas[this.carpetas.length - 1].id + 1;
            this.carpetas.push({id:id,nombre:this.nombre_carpeta,variantes:[]});
            this.cerrarModal();
        },
        abrirVariante(id,index){
            //abre la variante para su edicion
            for(let variante of this.itemsVariantes){
                if(variante.id == id){
                    this.abrirContrato(variante);
                }
            }
        },

//funciones empleados
        async assingEmployees(){
            //asigna los empleados seleccionados para el contrato actual
            this.empleadosAsignados = this.employees;
            
            /*if(this.isEditing){   
                //se puede mantener la funciona de guarddado o crear una nueva que solo guarde empleados
                await this.guardarContrato();
            }*/
            
            this.cerrarModal();
                Notify.Success("Documentos asignados",this.employees.length + " empleado(s) se asignaron a al documento.");
        },
        unassignEmployee(index){
            //desasigna los empleados seleccionados en el contrato actual
            this.empleadosAsignados.splice(index,1);
            this.employees = [];
            for(let empleado of this.empleadosAsignados){
                this.employees.push(empleado.empleado.id);
            }
            
        },
        selectAllEmployees(){
            //funcion para seleccionar y deseleccionar todos
            if(this.checkAll){
                for(let empleado of this.itemsEmpleados){
                    this.employees.push(empleado);
                }
            }else{
                this.employees = [];
            }
        },
        imgDrawer(foto){
            //devuelve la imagen configurada del empleado.
            //console.log(foto);
            return urlImg.getURL() + foto;
        },
        
//funciones del modulo
        
        async getClientes(clientes = null){
            let param = { activo:true, paginate:false};
            await clientesApi.getClientes(param).then((response) => {
                this.clientes = response.data;
                this.itemsClientes = response.data;
            })
            .catch(err =>{
                Notify.ErrorToast("Ocurrió un error al cargar los clientes.")
            })
        },
        async getRegistroPatronal(){
            this.registroPatronal = [];
            let parametros = { activo:true, paginate:false, order:"asc", cliente_id: this.contrato.cliente_id }
            await apiRegistroPatronal.find(parametros).then((response) => {
                let format = response.data;
                if(response.data.length != 0){
                    format.forEach( item => {
                        item.nombreClave = item.nombre + ' - ' + item.clave
                        this.registroPatronal.push(item);
                    })
                }
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Registros Patronales.");
            });
        },
        async getEmpresas(cliente){
            let param = { activo:true, paginate:false, cliente_id: cliente};
            await empresasApi.getEmpresas(param).then((response) => {
                this.empresas = response.data;
            })
            .catch(err =>{
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.")
            })
        },
        async getSucursales(empresa){
            let param = { activo:true, paginate:false, empresa_id: empresa};
            await sucursalesApi.getSucursales(param).then((response) => {
                this.sucursales = response.data;
            })
            .catch(err =>{
                Notify.ErrorToast("Ocurrió un error al cargar las sucursales.")
            })
        },
        async getContratos(){
            console.log(this.rp_seleccionados);
            let param = {
                activo: true,
                paginate: false,
                //registro_patronal_id: rp,
                arrayRegistroPatronal: this.rp_seleccionados.length == 0 ? null:JSON.stringify(this.rp_seleccionados),
                sistema: 'nominas',
                es_otro_documento: false
            }

            apiContratos.find(param).then((resp) => {
                this.rpContratos = resp.data
            }).catch((err) => {
                Notify.ErrorToast('Hubo un error al cargar los contratos')
                console.log(err);
            })
        },
        agregarImagen(){

            let formData = new FormData();
            if(this.rol == 'root'){
                formData.append("cliente_id", this.contrato.cliente_id);
            }
            else{
                formData.append("cliente_id", this.datosLogin.cliente_id);
            }
            
            formData.append("archivo", this.archivo);

            apiContratos.addImage(formData)
            .then((result) => {
                this.nombre_archivo = null;
                this.archivo = null;
                this.$apollo.queries.contratosImagenes.refetch();
                Notify.Success("Listo","La imagen se agrego correctamente");
            })
            .catch((err) => {
                Notify.ErrorToast(err);
            });   
        },
        pickFile(){
            const Input = this.$refs.image;
            Input.type = 'text';
            Input.type = 'file';
            Input.click();
        },
        onFilePicked(e){
            const files = e.target.files

            if(files[0] !== undefined){
                this.nombre_archivo = files[0].name;
                if(this.nombre_archivo.lastIndexOf('.') <= 0){
                return;
                }
                const fr = new FileReader();
                fr.readAsDataURL(files[0]);
                fr.addEventListener('load',() =>{
                this.archivo = files[0];
                });
            }
            else{
                this.nombre_archivo = null;
                this.imageUrl = null;
            }
        },
        eliminarImagen(id){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar esta imagen?",
                "Eliminar",
                () => {
                    apiContratos.deleteImage(id)
                    .then((result) => {
                        this.$apollo.queries.contratosImagenes.refetch();
                        Notify.Success("Listo","La imagen se elimino correctamente");
                    })
                    .catch((err) => {
                        Notify.ErrorToast(err);
                    });   
                }
            );
        },
        insertarVariable(variable = null){
            if(variable == null){
                return;
            }

            this.contrato.contenido += variable;
        },
        buscador(){
            //console.log(this.searchName);
        },
        verificarCambios(fnCancelar = null){
            this.cambios = false;
            Notify.Confirm("¡Tienes cambios sin guardar!","Si sales del documento sin guardar perderas los movimientos","Guardar","Continuar, sin guardar",()=>{this.guardarContrato();},fnCancelar);
             
        },
        /* getTiposContratosSAT(){
            //se llama a tipo de contratos
            let parametros = { activo:true, paginate:false, order:"asc" }
            apiTipoContratoSAT.find(parametros).then((response) => {
                let format = response.data;
                if(response.data.length != 0){
                    format.forEach( item => {
                        item.claveDescripcion = item.clave + ' - ' + item.descripcion
                        this.tiposContrato.push(item);
                    })
                }
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Tipos de contratos.");
            });
        }, */
        abrirModal(tipo){
            //manejador de modales asignar, variante y carpeta
            if(tipo == 'asignar'){
                this.dialogEmpleados = true;
            }else if(tipo == 'variante'){
                this.carpeta_value = null;
                this.isArchive = false;
                this.imgDialogVariante = "/static/modal/crear_carpeta.svg";
                this.titleDialogVariante = "Crear variante";
                this.nombre_variante = this.contrato.nombre;
                this.dialogVariantes = true;
            }else if(tipo == "carpeta"){
                this.nombre_carpeta = "";
                this.isArchive =true;
                this.imgDialogVariante = "/static/modal/crear_carpeta.svg";
                this.titleDialogVariante = "Agregar carpeta";
                this.dialogVariantes = true;
            }
        },
        async generarContratos(){
            
            let sefl = this;
            this.btnGuardar = true;
            this.invalidBtnConsultar = true;
            this.btnCerrar = true;
            this.overlay = true;

            let url = "https://apidevmid19.pbmg.com.mx/api/contratos/generar";
            const FileDownload = require("js-file-download");
            var today = new Date();
            let nombre;
            nombre = this.contrato.nombre + ".pdf";
            let empleadosAsignados = [];
            for(let item of this.empleadosAsignados){
                empleadosAsignados.push(item.id);
            }
            let params = {contrato_formato_id : this.contrato.id,
                        fecha_inicio : this.contrato.fecha_inicio,
                        fecha_fin : this.contrato.fecha_fin,
                        empleados : empleadosAsignados}

            if(params.fecha_inicio == null || params.fecha_inicio == undefined || params.fecha_inicio == ""){
                Notify.ErrorToast("El campo Fecha inicio es obligatorio.");
                this.overlay = false;
                return;
            }else if(params.empleados.length == 0){
                Notify.ErrorToast("No cuentas con empleados asignados para este contrato.");
                this.overlay = false;
                return;
            }
            try {
                await Vue.axios({
                method: "POST",
                responseType: "blob",
                url: url,
                data: params,
                }).then((response) => {
                    FileDownload(response.data,nombre);
                    this.overlay = false;
                });
            } catch (err) {
                Notify.ErrorToast(err);
                this.overlay = false;
            }
        },
        cerrarModal(){
            this.dialogVariantes = false;
            this.dialogEmpleados = false;
            this.dialogImagenes = false;
        },
        resetValues(){
            //Restea variables para el remanejo del modulo
            this.$nextTick(function () {
                if(!this.cambios){
                    
                    this.contrato={
                        id                  : 0,
                        cliente_id          : this.rol != 'root' ? this.contrato.cliente_id : null,
                        referencia_contrato_id: null,
                        tipo : "EMPLEADOS",
                        nombre: null,
                        fecha_inicio: "",
                        fecha_fin: "",
                        variante: false,
                        carpeta: "",
                        contenido: "",
                        empleados: [],
                        es_otro_documento: true
                    },
                    this.itemsContratos = [];
                    this.empleadosAsignados = [];
                    this.carpetas = [{id:1,nombre:"General",variantes:[]}],
                    this.isEditing = false;
                    this.isVariant = false;
                    this.cambios = false;
                    this.selectedArichive = -1;
                    this.tiene_relacion = false
                    this.tiene_archivo = false
                    this.contratos_seleccionados = []
                    this.rp_seleccionados = []
                    this.rpContratos = []
                    this.registroPatronal = []

                    this.filters = {AND:[{column: "CLIENTE_ID", operator:"IS_NULL"},{column:"EMPRESA_ID",operator:"IS_NULL"},{column:"SUCURSAL_ID",operator:"IS_NULL"},{column:"TIPO",value:"EMPLEADOS"}]}
                    if(this.$refs.FechaInicio != undefined || this.$refs.FechaInicio != null){
                        this.$refs.FechaInicio.resetDate();
                    }
                    if(this.$refs.FechaFinal != undefined || this.$refs.FechaFinal != null){
                        this.$refs.FechaFinal.resetDate();
                    }
                }else{
                    this.verificarCambios(()=>{this.resetValues()});
                }
            });
        },

        getCampos(){
            let param = { 
                activo: true, 
                paginate: false,
                cliente_id: this.cliente_value
            };
            this.itemsCampos = [];
            apiContratos.findCampos(param).then((response) => {
                this.itemsCampos = response.data;
            })
            .catch((error) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los campos.");
            });
        },


    },
    mounted () {
        //this.getTiposContratosSAT();

        if(this.rol != 'root'){
            this.contrato.cliente_id = this.datosLogin.cliente_id;
        }
        else{
            this.getClientes();
        }

        this.$apollo.queries.empleados.skip = false;
        this.$apollo.queries.contratosFormatos.skip = false;
        this.$apollo.queries.contratosVariables.skip = false;
        this.$apollo.queries.contratosImagenes.skip = false;
        this.isLoading = false;
    },
    created () {
        this.datosLogin = this.$session.get("usuario");
        
        if(this.datosLogin.rol.name == "admin-sucursal"){
            this.$router.push("/dashboard");
            Notify.Warning("¡Denegado!","Tu Rol no permite el acceso a esta vista.");
        }

        this.rol = this.datosLogin.rol.name;

    },
    apollo: {
        empleados: {
            query       : queries.getEmpleadosQuery,
            fetchPolicy : 'no-cache',
            variables() {
                return {
                    whereConditions : {AND:[{column:"EMPRESA_ID",value:10}]},
                    numberItems : 100,
                    numberPage  : 1,
                    fieldOrder  : 'nombre',
                    Order       : 'ASC',
                }
            },
            skip() {
                return true;
            },
            result({loading, error}) {
                if(this.empleados.data){
                    this.empleados.data.forEach(item =>{
                        item.nombre_completo = item.nombre + ' ' + item.apellido_paterno + ' ' + item.apellido_materno;
                    });
                    this.itemsEmpleados = this.empleados.data;
                }
            },
        },
        contratosFormatos: {
            query       : queriesC.contratosFormatosTableQuery,
            fetchPolicy : 'no-cache',
            variables() {
                return {
                    whereConditions : this.filters,
                    numberItems : 10000,
                    numberPage  : 1,
                    fieldOrder  : 'consecutivo',
                    Order       : 'ASC',
                }
            },
            skip() {
                return true;
            },
            result({loading, error}) {
                if(this.contratosFormatos.data){
                    /*let contratos = [];
                    let variantes = [];

                    for(let contrato of this.contratosFormatos.data){
                        if(!contrato.variante){
                            contratos.push(contrato);
                        }else{
                            variantes.push(contrato);
                        }
                    }

                    this.itemsContratos = contratos;
                    this.itemsVariantes = variantes;*/
                    let contratos = contratos = JSON.parse(JSON.stringify(this.contratosFormatos.data));
                    //console.log(contratos);

                    contratos.map(item => {
                        //let rp = item.registroPatronal !== null ? " - "+item.registroPatronal.nombre:""
                        //item.claveDescripcion = item.tipoContrato.clave+" - "+item.nombre+rp;
                        item.claveDescripcion = item.nombre;
                    });

                    this.itemsContratos = contratos;
                    this.Loading = false;
                }
            },
        },
        contratosVariables: {
            query       : queriesC.contratosVariablesQuery,
            fetchPolicy : 'no-cache',
            variables() {
                return {
                    whereConditions : {AND: [{ column: "TIPO_VARIABLE", value: "EMPLEADOS"}]},
                    numberItems : 10000,
                    numberPage  : 1,
                    fieldOrder  : 'nombre',
                    Order       : 'ASC',
                }
            },
            skip() {
                return true;
            },
            result({loading, error}) {
                if(this.contratosVariables.data){
                    //console.log(this.contratosVariables)
                }
            },
        },
        contratosImagenes: {
            query       : queriesC.contratosImagenesQuery,
            fetchPolicy : 'no-cache',
            variables() {
                return {
                    whereConditions : {AND: [{ column: "CLIENTE_ID", value:this.contrato.cliente_id}]},
                    numberItems : 10000,
                    numberPage  : 1,
                    fieldOrder  : 'id',
                    Order       : 'ASC',
                }
            },
            skip() {
                return true;
            },
            result({loading, error}) {
                if(this.contratosImagenes){
                    //console.log(this.contratosImagenes);
                }
            },
        },
    }
}
</script>

<style scoped>
    .palabrasClave{
        display: block;
        height: 130px;
        overflow-y: scroll;
        scroll-behavior: smooth;
    }
    .btnVariable{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-transform: none;
        letter-spacing: 0.0771724px;
        color: #828282;
    }
    .imgLibrary{
        width: 33% !important;
    }
    .tabText{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1E2245;
        text-transform: none;
    }
    .sliderPrev{
        width: 100%;
        height: 80%;
        margin-bottom: 10px;
    }

    .btnContrato{
        width: 100%;
        height: 100% !important;
        background:#ffffff !important;
        border: 2.54731px solid #E0E0E0;
    }

    .slider-container{
        min-width: 21vh;
        height: 100%;
        padding:21px;
        background: #FAFAFA;
        margin-right:3px;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #1E2144;
    }

    .searchbox{
        width: 100%;
    }
    .buscador{
        margin-right:auto;
        margin-left:auto;
        background: #FFFFFF;
        box-sizing: border-box;
        border-radius: 10px;
    } 
    .imgDrawer{
        width: 36px;
        height: 36px;
        border-radius: 150px;
        background: #828282;
    }
    .employeeContainer{
        background: #FFFFFF;
        max-height: 450px;
    }

    .employeeContent{
        padding-left:10px;
    }

    .employeeContent h2{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 125%;
        color: #1E2144;
    }

    .employeeContent p{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 125%;
        color: #828282;
    }

    .borde-card{
        padding:0px 19px 0px 19px;
        border-radius:10px !important;
    }

    .carpetas{
        min-height: 52px;
        margin-bottom:20px;
        background: #F9F9F9 !important;
        border: 1px solid #AEB2D3;
        border-radius: 7.71724px;
        box-shadow: 0px 0px 0px rgba(16, 28, 90, 0.1);
    }

    .activeCarpetas{
        border: 1px solid #1E2144;
        background: #1E2144 !important;
    }

    .add{
        background: #828282 !important;
        box-shadow: 0px 5px 12px rgba(16, 28, 90, 0.1);
        min-height: 52px;
        margin-bottom:20px
    }

    .contratos{
        background: #FFFFFF !important;
        min-height: 42px;
        border: 1px solid #BDBDBD;
        box-shadow: 0px 0px 0px;
        border-radius: 5px !important;
        margin-bottom: 10px;
    }
    .archive-title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 11px;
        letter-spacing: 0.0771724px;
        color: #828282;
        text-transform: none !important;
        padding:0px;
        height: 22px;
        margin-top:auto;
        margin-bottom: auto;
        padding-top:5px;
    }
    .activeTitle{
        color: #FFFFFF;
    }
    .archive-length{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        color: #BDBDBD;
        text-transform: none !important;
        padding:0px;
        height: 22px;
        margin-top:auto;
        margin-bottom: auto;
        padding-top:5px;
        border: 1.8px solid #BDBDBD;
        box-sizing: border-box;
        border-radius: 7px;
    }
    .activeLength{
        color:#FFFFFF;
        border: 1.8px solid #FFFFFF;
    }


    .contract-title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #828282;
        text-transform: none !important;
        padding:0px;
        height: 22px;
        margin-top:auto;
        margin-bottom: auto;
        padding-top:2px;
    }

    .sub-title{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #4F4F4F;
    }

    .floating-text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #828282;
    }

    .floating-title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #1E2245;
    }

    .mute{
        font-weight: 600;
        color: #828282;
    }

    .hr{
        margin-top: 10px;
    }
    .logo{
        width: 150px;
    }

    .left-menu{
        background: #FFFFFF;
        border-radius:5px;
        height: 70vh;
        /* -webkit-box-shadow: inset 1px -15px 18px -11px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: inset 1px -15px 18px -11px rgba(0, 0, 0, 0.15);
        box-shadow: inset 1px -15px 18px -11px rgba(0, 0, 0, 0.15); */
    }

    .editor{
        background: #FFFFFF;
        max-height: 60vh;
    }

    .document-slider{
        height: 20vh;
    }

    .breadcrumb{
        padding-left:48px;
    }

    .margen {
        height: 48px;
        margin-bottom: 10px;
        margin-left: 1px;
    }

    #loading{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70vh;
    }


    .center{
        padding-left:32px;
        padding-right:32px;
        margin-left: auto;
        margin-right: auto;
    }

    .accionBtns{
        margin-top:-20px;
        padding-right:25px;
    }

    .btnContrats.theme--light.v-btn {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #FFFFFF;
        text-transform: capitalize;
    }

    .material-icons-outlined {
        font-family: 'Material Icons Outlined';
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
    }  
    .slide-enter-active {
        -moz-transition-duration: 0.2s;
        -webkit-transition-duration: 0.2s;
        -o-transition-duration: 0.2s;
        transition-duration: 0.2s;
        -moz-transition-timing-function: ease-in;
        -webkit-transition-timing-function: ease-in;
        -o-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
    }

    .slide-leave-active {
        -moz-transition-duration: 0.3s;
        -webkit-transition-duration: 0.3s;
        -o-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }

    .slide-enter-to, .slide-leave {
        max-height: 100px;
        overflow: hidden;
    }

    .slide-enter, .slide-leave-to {
        overflow: hidden;
        max-height: 0;
    }

    .icon-container {
        width: 36px;
        height: 36px;
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 100%;
        text-align: center;
        padding: 5px;
        transition: 0.2s;
    }

    .icon-container:hover {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 15px 15px 0px;
       -webkit-transform: translatey(-5px);
        transform: translatey(-5px);
        cursor: pointer;
    }

    .remove{
        background: #F8F8F8;
        padding: 8px;
        box-shadow: 0px 0px 0px;
        transition: 0.5s;
    }

    .remove:hover {
        background: #1E2245;
        box-shadow: 0px 0px 0px  rgba(0, 0, 0, 0.1);
    }
    
    .document-editor {
        border: 1px solid var(--ck-color-base-border);
        border-radius: var(--ck-border-radius);

        /* Set vertical boundaries for the document editor. */
        max-height: 700px;

        /* This element is a flex container for easier rendering. */
        display: flex;
        flex-flow: column nowrap;
    }

    .document-editor__toolbar {
        /* Make sure the toolbar container is always above the editable. */
        z-index: 1;

        /* Create the illusion of the toolbar floating over the editable. */
        box-shadow: 0px 0px 0px 0px hsla( 0,0%,0%,.2 );

        /* Use the CKEditor CSS variables to keep the UI consistent. */
        border: 0px solid;
    }

    /* Adjust the look of the toolbar inside the container. */
    .document-editor__toolbar .ck-toolbar {
        border: 0px;
        background: red !important;
        border-radius: 50px !important;
    }

    /* Make the editable container look like the inside of a native word processor application. */
    .document-editor__editable-container {
        padding: calc( 2 * var(--ck-spacing-large) );
        background: #FAFAFA;
        /* Make it possible to scroll the "page" of the edited content. */
        overflow-y: scroll;
        height: 100%;

    }

    .document-editor__editable-container .ck-editor__editable {
        /* Set the dimensions of the "page". */
        width: 25.6cm;
        min-height: 35.6cm;
        zoom:90%;
        /* Keep the "page" off the boundaries of the container. */
        padding: 1cm 2cm 2cm;

        border: 1px hsl( 0,0%,82.7% ) solid;
        border-radius: var(--ck-border-radius);
        background: white;

        /* The "page" should cast a slight shadow (3D illusion). */
        box-shadow: 0 0 5px hsla( 0,0%,0%,.1 );

        /* Center the "page". */
        margin: 0 auto;
    }

    /* Set the default font for the "page" of the content. */
    .document-editor .ck-content,
    .document-editor .ck-heading-dropdown .ck-list .ck-button__label {
        font: 16px/1.6 "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    /* Adjust the headings dropdown to host some larger heading styles. */
    .document-editor .ck-heading-dropdown .ck-list .ck-button__label {
        line-height: calc( 1.7 * var(--ck-line-height-base) * var(--ck-font-size-base) );
        min-width: 6em;
    }

    /* Scale down all heading previews because they are way too big to be presented in the UI.
    Preserve the relative scale, though. */
    .document-editor .ck-heading-dropdown .ck-list .ck-button:not(.ck-heading_paragraph) .ck-button__label {
        transform: scale(0.8);
        transform-origin: left;
    }

    /* Set the styles for "Heading 1". */
    .document-editor .ck-content h2,
    .document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
        font-size: 2.18em;
        font-weight: normal;
    }

    .document-editor .ck-content h2 {
        line-height: 1.37em;
        padding-top: .342em;
        margin-bottom: .142em;
    }

    /* Set the styles for "Heading 2". */
    .document-editor .ck-content h3,
    .document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
        font-size: 1.75em;
        font-weight: normal;
        color: hsl( 203, 100%, 50% );
    }

    .document-editor .ck-heading-dropdown .ck-heading_heading2.ck-on .ck-button__label {
        color: var(--ck-color-list-button-on-text);
    }

    /* Set the styles for "Heading 2". */
    .document-editor .ck-content h3 {
        line-height: 1.86em;
        padding-top: .171em;
        margin-bottom: .357em;
    }

    /* Set the styles for "Heading 3". */
    .document-editor .ck-content h4,
    .document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
        font-size: 1.31em;
        font-weight: bold;
    }

    .document-editor .ck-content h4 {
        line-height: 1.24em;
        padding-top: .286em;
        margin-bottom: .952em;
    }

    /* Set the styles for "Paragraph". */
    .document-editor .ck-content p {
        font-size: 1em;
        line-height: 1.63em;
        padding-top: .5em;
        margin-bottom: 1.13em;
    }

    /* Make the block quoted text serif with some additional spacing. */
    .document-editor .ck-content blockquote {
        font-family: Georgia, serif;
        margin-left: calc( 2 * var(--ck-spacing-large) );
        margin-right: calc( 2 * var(--ck-spacing-large) );
    }

</style>
